import 'core-js/features/array/at';
import * as Sentry from "@sentry/vue";
import {createStore} from "vuex";
import {v4 as uuidv4} from "uuid";
import permeabilities, {ConcretePavement} from "@/components/main_content/tabs_content/design_run/PermeabilityUtils";
import {BASE_URL} from "@/constants";
import {mm_h, rainfall} from "@/units";
import {request as fetch, updateUserProfile} from "@/auth";
import {calculationRelevant, documentRelevant, saved} from "@/store/decorators";

/**
 * Deep clone whatever is passed in
 * @template T
 * @param {T} thing The thing to clone
 * @returns {T} A clone of the given parameter
 * @see https://developer.mozilla.org/en-US/docs/Web/API/structuredClone A more hopeful deep cloning future
 */
function clone(thing) {
    if (Array.isArray(thing)) {
        //return [...thing]; //Shallow clones
        return thing.map(element => clone(element));
    } else if (typeof thing === 'function') {
        throw TypeError("Tried to clone a function");
    } else if (thing === Object(thing)) {
        //return {...thing}; //Shallow clones
        //Simple enough but carries a few caveats on the way out:
        //Dates will get converted to strings (same as Date#toISOString), anything undefined is lost,
        //Infinity will turn into null, regex will turn into an empty object
        return JSON.parse(JSON.stringify(thing));
    } else {
        return thing;
    }
}

/**
 * @typedef AccessoryDetails
 * @property {string} [product] The slug of the product this accessory represents
 * @property {number?} diameter_mm The discharge size this accessory has, if any
 * @property {number} distance_m The distance this accessory is from the discharge
 * @property {string} type The type of accessory this is
 */
/**
 * @typedef {AccessoryDetails} Accessory An accessory for a run
 * @property {string} id The unique identifier for this accessory
 * @property {boolean} [toBeConfirmed] Whether this accessory is complete and suitable for using
 * @property {boolean} [automatic] Whether this accessory was added automatically as part of a calculation
 */
/**
 * @typedef PointInflow
 * @property {string} id The unique identifier for this inflow
 * @property {number} distance_m The distance this inflow is from the discharge
 * @property {string?} source The run which this inflow represents, or null if independent
 * @property {number} flow_lps The water this inflow is adding
 * @property {number} [source_index] The index of the run this inflow represents, if source is not null
 * @property {string?} [name] The name of the run this inflow represents, if source is not null
 * @property {string} connection The way this inflow connects to a channel
 * @property {boolean} [toBeConfirmed] Whether this inflow is complete and suitable for using
 * @property {string} [accessoryId] The ID of the access box this inflow is using
 */

export class Run {
    /** A unique identifier for this run
     * @type !string
     * @readonly
     */
    id = uuidv4();
    /**
     * The database ID this run was last saved with (if any)
     * @type ?number
     */
    @saved
    saveID = undefined;
    /**
     * Whether this run is the first in the project (so rainfall changes will apply to future runs)
     * @type !boolean
     */
    first = false;
    /**
     * The name of the run
     * @type !string
     */
    @documentRelevant
    @saved
    name;
    /**
     * Any notes about this run
     * @type !string
     */
    @documentRelevant
    @saved
    notes = "";
    /**
     * A description of the run's location (i.e. corner of the site)
     * @type {string}
     */
    @documentRelevant
    @saved
    location = "";
    /**
     * The method used for calculating the run's hydraulic fill
     * @type {'GVF'|'HRW'}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    calculation_method = "GVF";

    /**
     * The maximum value the {@link #calculation_method} allows the {@link #channel_length_m} to be for this run
     * @return {number} The maximum value {@link #channel_length_m} can be (in metres)
     */
    get maxChannelLength() {
        return this.calculation_method === 'GVF' ? 3000 : 500;
    }

    /**
     * The maximum value the {@link #calculation_method} allows the {@link #ground_slope} to be for this run
     * @return {number} The maximum value {@link #ground_slope} can be
     */
    get maxSlopeAngle() {
      return this.calculation_method === 'GVF' ? 9.9 : 3.3;
    }

    /**
     * Default rainfall values for new runs to use
     */
    static rainfallDefaults = {
        /** Rainfall intensity to calculate the run with */
        rainfall_mm_per_hr: 50,
        /** ID of the selected rainfall area */
        location_id: null,
        /** The part of the UK the selected rainfall area is in (England & Wales/Scotland & Northern Ireland) */
        location_index: null,
        /** The name of the selected rainfall area */
        town: "",
        /** Whether to ignore the location and use the intensity values directly */
        override: true,
        /** The returning period (in years) */
        return_period: 30,
        /** The additional percentage to add on top of the normal rainfall numbers */
        climate_change_factor: 0,
        /** The number of minutes of rain */
        storm_duration: 30,
        /** Dimensionless ratio based on {@link #M5} divided by a 48 hour storm with a 5 year return period */
        r: null,
        /** The depth of rainfall (in mm) for a 60 minute storm with a 5 return period */
        M5: null,
        /** Rainfall intensities for storms of various lengths */
        intensities: [],
        /** Rainfall intensity for a 5 minute storm */
        fiveMinuteRate: 0,
        /** Rainfall intensity for a 15 minute storm */
        fifteenMinuteRate: 0,
        /** Rainfall intensity for a 60 minute storm */
        sixtyMinuteRate: 0,
    };
    @calculationRelevant
    @documentRelevant
    @saved
    rainfall = clone(Run.rainfallDefaults); //Make sure to clone so the run can have its own independent numbers

    /** @return {string|undefined} The name of the currently selected channel group */
    @calculationRelevant
    @documentRelevant
    @saved
    get channel_group() {
        return this.selectedSystem?.name;
    }

    /**
     * The length of the run in metres
     * @type {number}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    channel_length_m = 0;
    /**
     * The distance from each (long) edge of the channel to the edge of the catchment
     * @type {number}
     */
    @saved
    catchment_depth_m = 0;
    /**
     * The total catchment area of the run in square metres
     * @type {number}
     */
    @saved
    drainage_area_m2 = 0;
    @calculationRelevant
    @documentRelevant
    @saved
    pipe_length_m = 0;
    @calculationRelevant
    @documentRelevant
    @saved
    pipe_diameter_mm = 0;
    @calculationRelevant
    @documentRelevant
    @saved
    pipe_slope = 0;
    @calculationRelevant //Is it?
    @saved
    has_body_extension = false;
    @saved
    water_inflow = 0;

    /**
     * Whether the run has a rectangular (i.e. simple) or complex catchment
     * @type {boolean}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    get simple() {
        return this.catchmentShape === 'uniform';
    }

    irregularCatchmentData = {
        leftAreas: [],
        rightAreas: []
    };
    /**
     * The channels used for the run if {@link #advanced} is true
     * @see #advanced_design
     * @see #channelsConfirmedLength
     */
    @saved
    channels = [];
    /**
     * A special channel type used for concrete channels, always matches the length of the run
     * @see #channels
     */
    @saved
    concreteChannel = (() => {
        const run = this; //Need the outer instance
        return {
            width: 0,
            depth: 0,
            roughness: 1 / 40, //Avoid using 0 as a default so the inverse is real
            get number() {
                return 1;
            },
            get channel_length_mm() {
                return run.channel_length_m * 1000;
            },
            get width_mm() {
                return this.width;
            }
        };
    })();
    /**
     * Whether to display the ground slope directly or the elevation
     * @type {'slope'|'elevation'}
     */
    slopeSetting = 'slope';
    /**
     * The slope the run is at
     * @type {number}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    ground_slope = 0;
    /**
     * The vertical height of the head of the run in metres
     * @type {number}
     * @see #lowerElevation
     */
    upperElevation = 0;
    /**
     * The vertical height of the run discharge in metres
     * @type {number}
     * @see #upperElevation
     */
    lowerElevation = 0;
    /**
     * The actual permeability value for the {@link #permeability_description}.
     * Especially important for custom permeability where this represents the true value
     * @type {number}
     * @see #permeability_description
     */
    @documentRelevant
    @saved
    permeability = 1;
    /**
     * The permeability information for the run, irrelevant if not {@link #simple}
     * @type {{cssName: string, colour: string, name: string, value: number}}
     * @see #permeability
     */
    permeability_description = ConcretePavement;
    /**
     * Whether the run should be stepped or use a single size of channel
     * @type {boolean}
     */
    @calculationRelevant
    @saved
    stepped = false;
    /**
     * The (user) requested loading for the selected channel
     * @type {string}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    loading = undefined; // HICAP & Slotted channels only
    /**
     * The (user) requested grating for the selected channel
     * @type {?string}
     */
    @calculationRelevant
    @saved
    grating = undefined;
    /**
     * The point inflows this run has
     * @type {PointInflow[]}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    inflows = [];
    /**
     * The accessories this run has
     * @type {Accessory[]}
     */
    @documentRelevant
    @saved
    accessories = [];
    /** @returns {PointInflow[]} */
    get inflowsWithAccessBox() {
        return this.inflows.filter(inflow => inflow.connection === 'access-box');
    }
    /**
     * The maximum flow limit (in l/s) to put on the discharge
     * @type {number}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    controlled_outflow = 0;
    controlledDischargeResults = undefined;
    /**
     * The (slug of the) smallest channel size to attempt to use in the run
     * @type {string}
     */
    minimumChannelSize = '';
    @calculationRelevant
    @saved
    get minimum_channel_size() {
        return this.minimumChannelSize || undefined;
    }


    /**
     * Create a new run, automatically marking it as {@link #advanced} and/or {@link #first}
     * @param name The name for the new run
     * @param advanced Whether the new run should start in advanced mode
     * @param first Whether the new run is first in the project
     * @return {Run} A newly created run
     */
    static new(name, advanced, first = true) {
        const out = new Run(name);
        out.advanced = advanced;
        out.first = first;
        return out;
    }

    constructor(name, copy) {
        if (copy instanceof Run) {
            for (const property in copy) {
                switch (property) {
                    case 'id':
                    case 'saveID': //It's meant to be unique!
                        continue;
                    case 'first': //Conceptually there should only be one first run in a project
                        continue;
                    case 'concreteChannel': //This is a bit more special
                        continue;
                    case 'permeability_description':
                        //This must be a reference to a PermeabilityUtils objects when Custom
                        //The permeability dropdown is identifying by deep comparing objects
                        this.permeability_description = copy.permeability_description;
                        break;
                    default:
                        this[property] = clone(copy[property]);
                        break;
                }
            }
            if (copy.channels.includes(copy.concreteChannel)) {
                for (const property of ['width', 'depth', 'roughness']) {
                    this.concreteChannel[property] = copy.concreteChannel[property];
                }
                this.channels = [this.concreteChannel];
            } else if (this.channels.length) {
                this.channels = this.channels.map(({slug, number}) => {
                    const channel = this.channelChoices.find(channel => channel.slug === slug)
                    channel.number = number;
                    return channel;
                });
            }
        }

        this.name = name;
    }

    /**
     * Does the current run have an area that can be used for calculations
     * @return {boolean}
     */
    get hasValidArea() {
        if (this.simple && this.selectedSystem?.product_type !== 'P') {
            //Would produce a 7 digit+ number with 2 decimal places, i.e. too long for Django
            //Works also if the channel length is 0: Infinity < 10000 => false
            return (this.drainage_area_m2 / this.channel_length_m) / 2 < 10000;
        } else {
            return this.drainage_area_m2 > 0;
        }
    }

    @documentRelevant
    @saved
    get permeability_material() {
        return this.permeability_description.name;
    }

    /**
     * A list of all the catchment areas
     * @return {Object[]}
     */
    @calculationRelevant
    @documentRelevant
    @saved
    get complex_design() {
        if (this.selectedSystem?.product_type === 'P') return undefined;

        if (this.simple) {// Convert the simple area over length into rectangles
            const x = this.channel_length_m;
            let y = (this.drainage_area_m2 / this.channel_length_m) / 2;

            // Django expects no more than 6 digits and 2 decimal places
            y = y.toFixed(2);
            return [{
                "end_x": x,
                "end_y": y,
                "permeability": this.permeability,
                "permeability_material": this.permeability_description.name,
                "section": "Upper",
                "start_x": 0,
                "start_y": y
            }, {
                "end_x": x,
                "end_y": y,
                "permeability": this.permeability,
                "permeability_material": this.permeability_description.name,
                "section": "Lower",
                "start_x": 0,
                "start_y": y
            }];
        } else {
            const {leftAreas, rightAreas} = this.irregularCatchmentData;

            return [leftAreas, rightAreas].flatMap((areas, i) => {
                const section = i === 0 ? 'Upper' : 'Lower';
                console.assert(areas.slice(1).every((area, i) => area.xOrigin > areas[i].xOrigin),
                    `${section} areas are not in order:`, areas);

                let x = 0;
                return areas.map(area => {
                    return {
                        "start_x": x.toFixed(2),
                        "start_y": area.start_width_m.toFixed(2),
                        section,
                        "permeability": area.permeability_value,
                        "permeability_material": area.permeability.name,
                        "end_x": (x += +area.length_m).toFixed(2),
                        "end_y": area.end_width_m.toFixed(2),
                    };
                });
            });
        }
    }

    /**
     * Get whether the run has ever been successfully calculated
     *
     * @returns {boolean}
     */
    get isCalculated() {
        return this.results.calculated;
    }

    /**
     * Can the given run be used as an inflow into this run?
     *
     * @param {Run} run The run to try using as an inflow
     * @param {Map<string, Run>} runs Map of run ID to run, used to follow any runs acting as inflows
     * @return {boolean}
     */
    canFlowInto(run, runs) {
        if (this === run || !this.isCalculated || !run.isCalculated) return false;

        //Look for cycles to stop runs flowing into themselves
        for (const inflows = [...this.inflows]; inflows.length;) {
            const inflow = inflows.pop();
            const inflowRun = runs.get(inflow.source);
            if (inflowRun) {
                if (inflowRun === run) return false;
                inflows.push(...inflowRun.inflows);
            }
        }

        return true;
    }

    /**
     * Collect the accessories that this run has which are relevant for calculating the materials list
     *
     * @param {Run[]} runs The other runs present, used for finding shared trashboxes
     * @return {AccessoryDetails[]} The accessories to consider for calculating materials
     */
    calculationAccessories(runs) {
        const accessories = this.accessories.filter(accessory => !accessory.toBeConfirmed && !accessory.automatic);
        switch (this.dischargeSetting) {
            case 'outflow-in-trashbox':
                out: if (this.outflowTrashbox && !runs.some(run => run.id === this.outflowTrashbox)) {
                    //Add the selected outflow trash-box as an accessory, ensures the materials list includes it
                    for (const accessory of accessories) {
                        if (accessory.type === 'trash-box' && accessory.distance_m === 0) {
                            accessory.diameter_mm = this.outflowTrashbox;
                            break out;
                        }
                    }
                    accessories.push({
                        type: 'trash-box', distance_m: 0, diameter_mm: this.outflowTrashbox,
                    });
                }
                break;

            case 'end-cap-with-outlet':
                out: if (this.outflowEndcap) {
                    //Add the selected outflow end-cap as an accessory, ensures the materials list includes it
                    for (const accessory of accessories) {
                        if (accessory.distance_m === 0) {
                            switch (accessory.type) {
                                case 'end-cap':
                                    accessory.type = 'end-cap-with-outlet'; //We're putting a hole through it
                                // eslint-disable-next-line no-fallthrough
                                case 'end-cap-with-outlet':
                                    accessory.product = this.outflowEndcap;
                                    break out;
                            }
                        }
                    }
                    accessories.push({
                        type: 'end-cap-with-outlet', distance_m: 0, product: this.outflowEndcap,
                    });
                }
                break;
        }
        return accessories;
    }

    /** Return the data needed for performing calculations on */
    getCalculationData(runs) {
        const out = this.copyWith(calculationRelevant.fields);
        out.rainfall = {
            ...out.rainfall,
            rainfall_mm_per_hr: out.rainfall.rainfall_mm_per_hr.toFixed(out.rainfall.rainfall_mm_per_hr >= 100000 ? 2 : 3),
        };
        //Only send complete accessories... should come up with a decorator-y way to express this
        out.accessories = this.calculationAccessories(runs);
        out.inflows = out.inflows.filter(inflow => !inflow.toBeConfirmed);
        if (this.selectedSystem?.product_type === 'P' && this.water_inflow > 0) {
            out.inflows.push({
                connection: 'direct-pipe',
                distance_m: 0,
                flow_lps: this.water_inflow,
            });
        }
        return out;
    }

    /** Return the data needed for producing PDFs from */
    getDocumentData(runs) {
        const out = this.copyWith(documentRelevant.fields);
        out.rainfall = {
            ...out.rainfall,
            rainfall_mm_per_hr: out.rainfall.rainfall_mm_per_hr.toFixed(out.rainfall.rainfall_mm_per_hr >= 100000 ? 2 : 3),
        };
        if (this.dischargeSetting === 'outflow-in-trashbox' && this.outflowTrashbox) {
            //If the trashbox is being shared Django will need the name of the run it's sharing with
            const sharedRun = runs.find(run => run.id === this.outflowTrashbox);
            if (sharedRun) {
                out.outflow_trashbox = sharedRun.outflowTrashbox;
                out.outflow_shared_trashbox = sharedRun.name;
            } else {
                out.outflow_trashbox = this.outflowTrashbox;
                out.outflow_shared_trashbox = runs.find(run => run.outflowTrashbox === this.id)?.name;
            }
        }
        return out;
    }

    /** Return the data needed for saving this run */
    getSavingData(runs) {
        const out = this.copyWith(saved.fields);
        out.catchment_depth_m = out.catchment_depth_m.toFixed(2);
        out.drainage_area_m2 = out.drainage_area_m2.toFixed(2);
        out.rainfall = {
            ...out.rainfall,
            rainfall_mm_per_hr: out.rainfall.rainfall_mm_per_hr.toFixed(out.rainfall.rainfall_mm_per_hr >= 100000 ? 2 : 3),
        };
        out.accessories = out.accessories.filter(accessory => !accessory.automatic && !accessory.toBeConfirmed);
        out.inflows = out.inflows.filter(inflow => !inflow.toBeConfirmed);
        if (this.dischargeSetting === 'outflow-in-trashbox' && this.outflowTrashbox) {
            //If the trashbox is being shared Django will need the name of the run it's sharing with
            const index = runs.findIndex(run => run.id === this.outflowTrashbox);
            if (index >= 0) {
                out.outflow_shared_trashbox = index;
            } else {
                out.outflow_trashbox = this.outflowTrashbox;
            }
        }
        return out;
    }

    /**
     * Shallow clone this run with just the specified properties
     *
     * @param {!string[]} properties The properties to copy over
     * @return {Object.<!string, *>} A new object
     * @private Would be properly private (with #) but private methods can't go on decorated classes yet :(
     */
    copyWith(properties) {
        const out = {};

        for (const property of properties) {
            out[property] = this[property];
        }

        return out;
    }

    @calculationRelevant //Point products use this
    @documentRelevant
    get catchment_area_m2() {
        return +this.drainage_area_m2.toFixed(2);
    }

    @calculationRelevant
    @documentRelevant
    get advanced_design() {
        if (!this.advanced) return undefined;

        const channelMap = new Map(this.channels.map(channel => [channel, this.channelChoices.indexOf(channel)]));
        const channels = this.channels //Sort the channels deepest to shallowest
            .sort((a, b) => channelMap.get(b) - channelMap.get(a))
            .map(channel => channel === this.concreteChannel ? channel : ({
                channel: channel.slug,
                count: channel.number,
            }));

        //Only send the cutting and drilling information if it should actually be used
        return {
            channels,
            headCutting: this.hasHeadCutting ? this.headCutting : undefined,
            dischargeCutting: this.hasDischargeCutting ? this.dischargeCutting : undefined,
            drilling: this.hasDrillingAtLast ? this.drilling : undefined,
        }
    }

    @calculationRelevant
    @documentRelevant
    get controlled_discharge_details() {
        const results = this.controlledDischargeResults;
        //Send up just what Django wants, avoids sending images back for no reason
        return results ? {
            channel_name: results.channel_name,
            max_depth_mm: results.max_depth_mm,
            orifice_diameter_mm: results.orifice_diameter_mm,
        } : undefined;
    }

    //Additional things currently present client side which the server doesn't know about ==>
    get channelLength() {
        return this.channel_length_m;
    }

    /**
     * Whether the run has been created in advanced mode
     * @type {boolean}
     */
    @saved
    advanced = false
    /**
     * The layout of the run's catchment, determines whether the run is {@link #simple}
     * @type {'uniform'|'irregular'}
     */
    catchmentShape = 'uniform'
    /** The object returned by the server after a successful calculation */
    @documentRelevant
    results = {
        calculated: false,
        materials: [],
        bwc: [],
        xSection: '',
        channelInternalWidth: 0,
        channelType: '', //channel_product_type
        invertDepth: 0,
        overallDepth: 0,
        loading: false, //system_loading
        flowVelocity: 0,
        channelDischarge: 0,
        usedVolume: 0,
        totalSystemVolume: 0, //total_system_volume
        segments: [], //segments of an ordinary user's hydraulic profile
    }

    get dischargeTrashboxLength() {
        if (this.dischargeSetting === 'outflow-in-trashbox') {
            //FASERFIX 400 and 500 trashboxes are a metre long, all the others are 1/2 metre
            return this.selectedSystem.slug === 'faserfixsuper' && (
                this.selectedChannelType === '400' || this.selectedChannelType === '500'
            ) ? 1 : 0.5;
        }

        return 0;
    }

    get channelsConfirmedLength() {
        let adjustment = this.dischargeTrashboxLength; //In metres
        if (this.hasHeadCutting) {
            adjustment -= this.headCutting.distance_in_mm / 1000;
        }
        if (this.hasDischargeCutting) {
            adjustment -= this.dischargeCutting.distance_in_mm / 1000;
        }
        return this.channels.reduce((totalLength, channel) => {
            console.assert(Number.isFinite(channel.number));
            console.assert(Number.isFinite(channel.channel_length_mm));
            return totalLength + channel.number * channel.channel_length_mm / 1000;
        }, adjustment);
    }

    selectedSystem = null;
    @saved
    selectedChannelType = undefined;

    @saved
    hasHeadCutting = false
    @saved
    headCutting = {
        distance_in_mm: 0,
        angle_deg: 90,
        cut_from_left: false,
    };
    /**
     * Modification made to the channel at the discharge, only applies if {@link #advanced} is true
     * @type {'free-outflow'|'outflow-in-trashbox'|'end-cap-with-outlet'|'modification-last-element'}
     */
    @documentRelevant
    dischargeSetting = 'free-outflow';
    /**
     * ID of the selected endcap
     * @type {?number}
     */
    @documentRelevant
    @saved
    outflowEndcap = undefined;
    /**
     * Diameter (in mm) of selected trashbox discharge, or the ID of the run this run shares trashboxes with
     * @type {?number|string}
     */
    outflowTrashbox = undefined;
    // Cutting at last data
    @saved
    hasDischargeCutting = false
    @saved
    dischargeCutting = {
        distance_in_mm: 100,
        angle_deg: 90,
        cut_from_left: false,
    };

    // Drilling at last data
    @saved
    hasDrillingAtLast = false
    @saved
    drilling = {
        position: 'bottom',
        hole_distance_mm: 150,
        hole_diameter_mm: 50,
    };

    errors = {
        lengthsNotMatching: false
    }

    // Need for populating channels input in advance
    channelTypes = []
    channelChoices = []

    noBuildInFall = false
    hasInputChanged = false

    calculationError = false // if true, user should be able to reset run

}

/**
 * Finds the run with the given ID, or the currently selected one if not given
 *
 * @param state The store to take the information from
 * @param {Run[]} state.project.runs All runs currently known about
 * @param {number} state.selectedRun The index of the currently selected run
 * @param {string} [id] The ID of the run to find (optional)
 * @return {Run}
 */
function selectRun(state, id) {
    const selectedRun = state.project.runs[state.selectedRun];

    if (id !== undefined && id !== selectedRun.id) {
        return state.project.runs.find(run => run.id === id);
    } else {
        return selectedRun;
    }
}

/**
 * @typedef Country
 * @property {string} iso_code The ISO code for the country
 * @property {string} region The product region the country is in
 */
/**
 * @typedef User
 * @property {string} username The name of the user
 * @property {boolean} has_advanced_mode Whether the user can use advanced mode
 * @property {boolean} is_hauraton Whether the user works at Hauraton
 * @property {boolean} is_translator Whether the user can use the help translator
 * @property {boolean} is_marketing Whether the user can see the Channel Availability page
 * @property {boolean} is_admin Whether the user can see the admin page
 * @property {Country} country The user's country
 * @property {boolean} can_change_country Whether the user is allowed to change countries
 * @property {string} language The display language the user uses
 * @property {boolean} default_advanced_mode Whether to default new projects to advanced mode
 * @property {RainfallUnit} rainfallUnits The default rainfall units for new projects to use
 * @property {boolean} zero_at_discharge Whether zero on a backwater curve is at the discharge or head of a channel
 * @property {string} company The name of the company the user signed up with
 * @property {string} telephone The telephone number the user signed up with
 * @property {string} address The address the user signed up with
 * @property {string} city The city the user signed up with
 * @property {string} postcode The postcode the user signed up with
 */

/**
 * Work out the changes that need to be made to the accessories for the given run, based on its calculated materials
 *
 * @param {Run} currentRun
 */
function calculateAccessoryChange(currentRun) {
    function materialMapper(materials, name) {
        //Allow the accessory type to be independent of material information (i.e. passed in as a string)
        const accessoryType = typeof name !== 'function' ? () => name : name;
        return new Map(materials.flatMap(material => material.distances_m.map(
            distance_m => [distance_m, accessoryType(material, distance_m)]
        )));
    }

    const connectors = materialMapper(currentRun.results.connectors_materials, 'connection-plate');
    const boxes = materialMapper(currentRun.results.box_materials,
        (material, distance_m) => distance_m === 0 ? 'trash-box' : 'access-box');
    const concreteBoxes = new Map(currentRun.results.concrete_box_materials.map(distance_m => [distance_m, 'concrete-chamber']));
    const endCaps = materialMapper(currentRun.results.endcaps_materials,
        material => material.withOutlet ? 'end-cap-with-outlet' : 'end-cap');
    const accessCovers = materialMapper(currentRun.results.access_cover_materials, 'access-cover');
    const negatives = currentRun.accessories.reduce((map, accessory) => {
        return accessory.type === 'no-automatic' ? map.set(accessory.distance_m, accessory.type) : map;
    }, new Map());

    const [existingAccessories, lostAccessories] = currentRun.accessories.reduce((result, accessory) => {
        const [existing, lost] = result;
        let isKept;
        switch (accessory.type) {
            case 'access-box':
            case 'trash-box':
                isKept = boxes.has(accessory.distance_m);
                break;
            case 'end-cap':
            case 'end-cap-with-outlet':
                isKept = endCaps.has(accessory.distance_m);
                break;
            case 'concrete-chamber':
                isKept = concreteBoxes.has(accessory.distance_m);
                break;
            case 'connection-plate':
                isKept = connectors.has(accessory.distance_m);
                break;
            case 'access-cover':
                isKept = accessCovers.has(accessory.distance_m);
                break;
            case 'no-automatic':
                isKept = true;
                break;
            default:
                console.warn('Unexpected accessory type', accessory.type, accessory);
                isKept = false; //Some other type... definitely gone
                break;
        }
        (isKept ? existing : lost).push(accessory);
        return result;
    }, [[], []]);
    console.debug('Accessories lost in calculation', lostAccessories);

    /** Existing accessories, marked if they are non-automatic thus should be kept */
    const manualAccessoryMap = new Map(existingAccessories.map(
        accessory => [`${accessory.type}@${accessory.distance_m}`, !accessory.automatic]
    ));
    currentRun.accessories = [boxes, concreteBoxes, /*connectors,*/ endCaps, accessCovers, negatives].flatMap(accessoryType => {
        return Array.from(accessoryType, ([distance_m, type]) => ({
            id: uuidv4(),
            distance_m,
            type,
            toBeConfirmed: false,
            automatic: !manualAccessoryMap.get(`${type}@${distance_m}`),
        }));
    });

    linkInflowsToAccessBoxes(currentRun);
}

/**
 * Match up access-box connected inflows to their corresponding access-box accessory ID
 *
 * @param {Run} currentRun
 */
function linkInflowsToAccessBoxes(currentRun) {
    const lostInflowBoxes = [];
    for (const inflow of currentRun.inflowsWithAccessBox) {
        const accessory = currentRun.accessories.find(
            accessory => (accessory.type === 'access-box' || accessory.type === 'trash-box') && accessory.distance_m === inflow.distance_m
        );
        //console.debug(inflow, '=>', accessory);
        if (!accessory) {
            lostInflowBoxes.push(inflow);
            continue;
        }
        inflow.accessoryId = accessory.id;
    }
    if (lostInflowBoxes.length) {//This shouldn't happen, otherwise the inflows will use boxes the materials list doesn't have
        throw new Error(`Lost point inflow access boxes positioned at ${lostInflowBoxes.map(inflow => inflow.distance_m)}`);
    }
}

export const store = createStore({
    state: {
        registerForm: {
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            telephone: '',
            country: '',
            address: '',
            city: '',
            postcode: '',
            password: '',
            passwordConfirmation: '',
        },
        user: null,
        isCalculating: false,
        tabIndex: 0,
        selectedRun: 0,
        productGroups: null,
        project: {
            details: {
                saveID: undefined,
                name: '',
                date: '',
                location: '',
                reference: '',
                company: '',
                designer: '',
                telephone: '',
                email: '',
            },
            rainfallUnits: mm_h,
            changedRainfall: false,
            sharedWithHauraton: false,
            getDocumentData() {
                return {
                    details: {
                        ...this.details,
                        rainfall_units: this.rainfallUnits.stored,
                    },
                    runs: this.runs.filter(run => run.isCalculated).map(run => run.getDocumentData(this.runs)),
                };
            },
            getSavingData: function (extra = {}) {
                return {
                    ...extra,
                    project: {
                        ...this.details,
                        rainfall_units: this.rainfallUnits.stored,
                        default_rainfall: this.changedRainfall ? {
                            ...Run.rainfallDefaults,
                            rainfall_mm_per_hr: Run.rainfallDefaults.rainfall_mm_per_hr.toFixed(
                                Run.rainfallDefaults.rainfall_mm_per_hr >= 100000 ? 2 : 3
                            ),
                        } : undefined,
                    },
                    runs: this.runs.map((run, i, runs) => run.getSavingData(runs)),
                };
            },
            runs: [
                Run.new("Run 1", false),
            ]
        },
        skipSuperCriticalFlowWarnings: false,
    },
    getters: {
        /** @returns {Run[]} All runs the current project has */
        runs: state => state.project.runs,
        /** @returns {Run} The current run the user has selected */
        selectedRun: (state, getters) => getters.runs[state.selectedRun],
        /** @returns {string} The unique ID of the current run the user has selected */
        selectedRunId: (state, getters) => getters.selectedRun.id,
        /** @returns {string} The name of the current run the user has selected */
        selectedRunName: (state, getters) => getters.selectedRun.name,
        /** @returns {boolean} Whether the current run has been successfully calculated ever */
        isSelectedRunCalculated: (state, getters) => getters.selectedRun.isCalculated,
        /** @return {number} The current tab index  */
        tabIndex: (state) => {
            return state.tabIndex
        },
        getRegisterForm(state) {
            return state.registerForm
        },
        isUserAuthenticated(state) {
            return !!state.user;
        },
        canUseAdvancedMode(state, getters) {
            return getters.isUserAuthenticated && state.user.has_advanced_mode;
        },
        canSeeSharedProjects(state, getters) {
            return getters.isUserAuthenticated && state.user.is_hauraton;
        },
        isTranslator(state, getters) {
            return getters.isUserAuthenticated && state.user.is_translator;
        },
        isMarketing(state, getters) {
            return getters.isUserAuthenticated && state.user.is_marketing;
        },
        isStaff(state, getters) {
            return getters.isUserAuthenticated && state.user.is_admin;
        },
        isDischargeAtZero(state, getters) {
            return getters.isUserAuthenticated && state.user.zero_at_discharge;
        },
        projectRainfallUnits(state) {
            return state.project.rainfallUnits;
        },
        getRainfallDefaults() {
            return Run.rainfallDefaults;
        },
        getRainfallData(state) {
            return state.project.runs[state.selectedRun].rainfall
        },
        getChannelTypes(state) {
            return state.project.runs[state.selectedRun].channelTypes
        },
        getCalculationError(state) {
            return state.project.runs[state.selectedRun].calculationError
        },
        getNotes(state) {
            return state.project.runs[state.selectedRun].notes
        },
        getInflows(state) {
            return state.project.runs[state.selectedRun].inflows
        },
        getAccessories(state) {
            return state.project.runs[state.selectedRun].accessories
        },
        getPermeability(state) {
            return state.project.runs[state.selectedRun].permeability
        },
        getRunChannels(state) {
            return state.project.runs[state.selectedRun].channels
        },
        getGroundSlope(state) {
            return state.project.runs[state.selectedRun].ground_slope
        },
        pipe_length_m(state) {
            return state.project.runs[state.selectedRun].pipe_length_m
        },
        pipe_diameter_mm(state) {
            return state.project.runs[state.selectedRun].pipe_diameter_mm
        },
        pipeSlope(state) {
            return state.project.runs[state.selectedRun].pipe_slope;
        },
        waterInflow: (state, getters) => getters.selectedRun.water_inflow,
        /** @returns {boolean} Whether the current run has a point product extension */
        hasExtensionHat: (state, getters) => getters.selectedRun.has_body_extension,
        controlledDischarge: (state, getters) => getters.selectedRun.controlled_outflow,
        getIrregularAreas(state) {
            return state.project.runs[state.selectedRun].irregularCatchmentData
        },
        wentSuperCritical(state) {
            return state.project.runs[state.selectedRun].results?.went_super_critical;
        },
        showSuperCriticalWarning(state, getters) {
            return !state.skipSuperCriticalFlowWarnings && getters.wentSuperCritical;
        },
        getIsCalculated(state) {
            return state.project.runs[state.selectedRun].results.calculated
        },
        /** @returns {boolean} Whether the current run was calculated in advanced mode */
        wasAdvancedCalculation: (state, getters) => !!getters.selectedRun.results?.advanced,
        getIsCalculating(state) {
            return state.isCalculating
        },
        getHasInputChanged(state) {
            return state.project.runs[state.selectedRun].hasInputChanged
        },
        readyForFirstCalculation(state) {
            const currentRun = state.project.runs[state.selectedRun]
            if (!currentRun.results.calculated && !!currentRun.selectedSystem) {
                switch (currentRun.selectedSystem.product_type) {
                    case 'P':
                        return currentRun.drainage_area_m2 > 0; //Point products only need an area
                    case 'M':
                        if (currentRun.concreteChannel.width <= 0 || currentRun.concreteChannel.depth <= 0) return false;
                    // eslint-disable-next-line no-fallthrough
                    default:
                        return currentRun.channel_length_m > 0 && currentRun.drainage_area_m2 > 0;
                }
            }
            return false;
        },
        getRunLocation(state) {
            return state.project.runs[state.selectedRun].location
        },
        getChannelChoices(state) {
            return state.project.runs[state.selectedRun].channelChoices
        },
        getNoBuildInFall(state) {
            return state.project.runs[state.selectedRun].noBuildInFall
        },
        getSelectedChannelType(state) {
            return state.project.runs[state.selectedRun].selectedChannelType
        },
        isAdvancedMode(state) {
            return state.project.runs[state.selectedRun].advanced
        },
        getErrors(state) {
            return state.project.runs[state.selectedRun].errors
        },
        /** @returns {boolean} Whether the current run's channel group needs a loading specifying */
        needsLoading(state, getters) {
            return !['P', 'M'].includes(getters.getDrainageSystem?.product_type);
        },
        getLoading(state, getters) {
            const loading = state.project.runs[state.selectedRun].loading;
            // BIG BLS uses LCN ratings
            if (getters.getDrainageSystem?.slug === 'faserfix-big-bls') {
                  switch (loading) {
                    case 'L':
                      return 'LCN20';
                    case 'M':
                      return 'LCN50';
                    case 'N':
                      return 'LCN70';
                    case 'O':
                      return 'LCN110';
                  }
            }
            return getters.getDrainageSystem?.loading_classes.find(name => name.charAt(0) === loading);
        },
        /** @returns {boolean} Whether the current run's channel group needs a grating specifying */
        needsGrating(state, getters) {
            const channel = getters.getDrainageSystem;
            return channel !== null && [
                //'RECYFIX HICAP ',
            ].includes(channel.name);
        },
        /** @returns {?string} The current run's grating, or null if not set */
        getGrating(state, getters) {
            return getters.selectedRun.grating;
        },
        hasHeadCutting(state, getters) {
            return getters.selectedRun.hasHeadCutting;
        },
        getHeadCuttingDistance(state, getters) {
            return getters.selectedRun.headCutting.distance_in_mm;
        },
        getHeadCuttingAngle(state, getters) {
            return getters.selectedRun.headCutting.angle_deg;
        },
        getHeadCuttingSide(state, getters) {
            return getters.selectedRun.headCutting.cut_from_left;
        },
        dischargeSetting(state, getters) {
            return getters.selectedRun.dischargeSetting;
        },
        outflowTrashbox(state, getters) {
            return getters.selectedRun.outflowTrashbox;
        },
        outflowEndcap(state, getters) {
            return getters.selectedRun.outflowEndcap;
        },
        getHasDrillingAtLast(state) {
            return state.project.runs[state.selectedRun].hasDrillingAtLast
        },
        getDistanceEdgeToDrillingCenterValue(state, getters) {
            return getters.selectedRun.drilling.hole_distance_mm;
        },
        getDrillingDiameterValue(state, getters) {
            return getters.selectedRun.drilling.hole_diameter_mm;
        },
        getDrillingAtLastPosition(state, getters) {
            return getters.selectedRun.drilling.position;
        },
        hasDischargeCutting(state, getters) {
            return getters.selectedRun.hasDischargeCutting;
        },
        getDischargeCuttingDistance(state, getters) {
            return getters.selectedRun.dischargeCutting.distance_in_mm;
        },
        getDischargeCuttingAngle(state, getters) {
            return getters.selectedRun.dischargeCutting.angle_deg;
        },
        getDischargeCuttingSide(state, getters) {
            return getters.selectedRun.dischargeCutting.cut_from_left;
        },
        sharedTrashboxOutflow(state, getters) {
            const thisRun = getters.selectedRun;
            if (thisRun.dischargeSetting === 'outflow-in-trashbox' && thisRun.outflowTrashbox) {
                const sharedRun = state.project.runs.find(run => run.id === thisRun.outflowTrashbox);
                if (sharedRun) {
                    return sharedRun.results.channel_discharge;
                } else {
                    return state.project.runs.find(run => run.outflowTrashbox === thisRun.id)?.results.channel_discharge;
                }
            }
            return undefined;
        },
        /** Get a new {@link Run} unconnected to the active project */
        getDefaultRun() {
            return new Run("Default");
        },
        getChannelGroup(state) {
            return state.project.runs[state.selectedRun].channel_group
        },
        getPermeabilityName(state) {
            return state.project.runs[state.selectedRun].permeability_description
        },
        getChannelsConfirmedLength(state) {
            return state.selectedRun !== null ? state.project.runs[state.selectedRun].channelsConfirmedLength : state.project.runs[0].channelsConfirmedLength;
        },
        usedChannelLengthCorrect(state, getters) {
            const targetLength_m = getters.getChannelLength;
            const actualLength_m = getters.getChannelsConfirmedLength
            return targetLength_m === actualLength_m;
        },

        normalDrainageSystems(state) {
            return state.productGroups?.filter(s => s.product_type !== 'M');
        },
        advancedDrainageSystems(state) {
            return state.productGroups?.filter(s => s.product_type !== 'P');
        },
        getDrainageSystem(state) {
            "use strict";
            return state.project.runs[state.selectedRun].selectedSystem;
        },
        getChannelLength(state) {
            "use strict";
            return state.selectedRun !== null ? state.project.runs[state.selectedRun].channelLength : state.project.runs[0].channelLength;
        },
        getDrainageArea(state) {
            "use strict";
            return state.project.runs[state.selectedRun].drainage_area_m2;
        },
        getCatchmentShape(state) {
            "use strict";
            return state.selectedRun !== null ? state.selectedRun.catchmentShape : state.project.runs[0].catchmentShape;
        },
    },
    mutations: {
        updateRegisterForm(state, payload) {
            state.registerForm = payload
        },
        /**
         * Reset the user's details within the store, such as when logging out
         *
         * @param {store.state} state The currently stored things
         */
        resetUserAuthentication(state) {
          state.user = null;
          Sentry.setContext("User Details", null);
          Sentry.setUser(null);
        },
        /**
         * Put the user's details into the store, presumably from logging in
         *
         * @param {store.state} state The currently stored things
         * @param {User} user
         * @param {string} user.rainfall_units The rainfall units as their stored value
         */
        setUserDetails(state, user) {
            state.user = user;
            const {rainfall_units, default_advanced_mode} = user;
            console.assert(rainfall.has(rainfall_units), rainfall_units);
            //Load the user's default rainfall into the project too
            state.project.rainfallUnits = state.user.rainfallUnits = rainfall.get(rainfall_units);
            Sentry.setContext("User Details", {
                country: user.country.iso_code,
                language: user.language,
                rainfall_units: rainfall_units,
            });
            Sentry.setUser({username: user.username});
            //In this situation the run will still be unchanged, so the user won't notice the switch
            if (default_advanced_mode) state.project.runs[0].advanced = true;
        },
        /**
         * Update the user's details within the store
         *
         * @param {store.state} state The currently stored things
         * @param {User} user The new (potentially partial) user details to update with
         * @see #setProjectDefaults
         */
        updateUserDetails(state, user) {
            Object.assign(state.user, user);
            updateUserProfile({
                ...state.user,
                rainfall_units: state.user.rainfallUnits.stored,
                rainfallUnits: undefined, //Don't try store the object in local storage
            });
            Sentry.setContext("User Details", {
                country: state.user.country.iso_code,
                language: state.user.language,
                rainfall_units: state.user.rainfallUnits.stored,
            });
        },
        /**
         * Update the current project's rainfall units
         *
         * @param {store.state} state The currently stored things
         * @param {RainfallUnit} rainfallUnits The units for the current project to use
         * @see #setUserDetails
         */
        setProjectDefaults(state, {rainfallUnits}) {
            state.project.rainfallUnits = rainfallUnits;
        },
        setRainfallDefaults(state, payload) {
            Object.assign(Run.rainfallDefaults, payload);
            state.project.changedRainfall = true;
        }, setRainfallData(state, payload) {
            Object.assign(state.project.runs[state.selectedRun].rainfall, payload);
        },
        setChannelTypes(state, payload) {
            state.project.runs[state.selectedRun].channelTypes = payload
        },
        setCalculationError(state, payload) {
            state.project.runs[state.selectedRun].calculationError = payload
        },
        setSaveIDs(state, {saveID, runSaveIDs}) {
            state.project.details.saveID = saveID;
            console.assert(runSaveIDs.length === state.project.runs.length);
            runSaveIDs.forEach((saveID, i) => {
                state.project.runs[i].saveID = saveID;
            });
        },
        loadProject(state, {project, runs}) {
            Sentry.addBreadcrumb({
                category: 'loading',
                message: `Loading project ${project.saveID}`,
                level: Sentry.Severity.Info,
            });
            console.assert(!project.runs, 'Project has no runs?!'); //Avoid any accidents
            const rainfallUnits = rainfall.get(project.rainfall_units);
            delete project.rainfall_units; //Doesn't matter, but avoids any confusion
            const defaultRainfall = project.default_rainfall;
            delete project.default_rainfall; //Especially important this isn't saved if empty
            const sharedWithHauraton = !!project.share_with_hauraton;
            delete project.share_with_hauraton;
            Object.assign(state.project.details, project);
            state.project.rainfallUnits = rainfallUnits;
            if (defaultRainfall) {
                Object.assign(Run.rainfallDefaults, defaultRainfall);
                state.project.changedRainfall = true;
            } else {
                state.project.changedRainfall = false;
            }
            state.project.sharedWithHauraton = sharedWithHauraton;

            const old = state.project.runs.length;
            for (const run of runs) {
                this.commit('loadRun', run);
            }
            state.tabIndex = 0; //Make sure the selected run is sensible for the newly loaded project
            state.selectedRun = 0;
            state.project.runs.splice(0, old); //Clear existing runs
            state.project.runs[0].first = true;
            for (const run of state.project.runs) {
                for (const inflow of run.inflows) {
                    if (inflow.source_index) {
                        this.commit('updateInflow', {
                            run,
                            id: inflow.id,
                            source: state.project.runs.find(r => r.saveID === inflow.source_index)?.id,
                        });
                    }
                }
                if (run.outflow_shared_trashbox) {
                    run.outflowTrashbox = state.project.runs.find(r => r.saveID === run.outflow_shared_trashbox)?.id;
                    delete run.outflow_shared_trashbox;
                }
            }
            Sentry.addBreadcrumb({
                category: 'loading',
                message: `Loading project ${project.saveID} complete`,
                level: Sentry.Severity.Debug,
            });
        },
        addInflow(state, payload) {
            state.project.runs[state.selectedRun].inflows.push(payload)
        },
        addAccessory(state, payload) {
            state.project.runs[state.selectedRun].accessories.push(payload)
        },
        resetInflows(state) {
            const selectedRun = state.project.runs[state.selectedRun];
            const lostBoxes = new Set(selectedRun.inflowsWithAccessBox.map(inflow => inflow.accessoryId));
            selectedRun.inflows = [];
            selectedRun.accessories = selectedRun.accessories.filter(accessory => !lostBoxes.has(accessory.id));
        },
        resetAccessories(state) {
            const selectedRun = state.project.runs[state.selectedRun];
            const usedBoxes = new Set(selectedRun.inflowsWithAccessBox.map(map => map.accessoryId));
            selectedRun.accessories = selectedRun.accessories.filter(accessory => usedBoxes.has(accessory.id));
        },
        removeInflow(state, payload) {
            state.project.runs[state.selectedRun].inflows = state.project.runs[state.selectedRun].inflows.filter(i => {
                return i.id !== payload
            })
        },
        cancelAutomaticAccessory(state, id) {
            const accessory = state.project.runs[state.selectedRun].accessories.find(accessory => accessory.id === id);
            console.assert(accessory?.automatic, 'Tried to cancel non-automatic accessory', accessory);
            accessory.automatic = false;
            accessory.type = 'no-automatic';
        },
        removeAccessory(state, payload) {
            state.project.runs[state.selectedRun].accessories = state.project.runs[state.selectedRun].accessories.filter(a => a.id !== payload)
        },
        updateInflow(state, payload) {
            const objectToUpdate = (payload.run || state.project.runs[state.selectedRun]).inflows.find(a => {
                return a.id === payload.id
            })
            if (payload.distance_m !== undefined) objectToUpdate.distance_m = payload.distance_m;
            if (payload.toBeConfirmed !== undefined) objectToUpdate.toBeConfirmed = payload.toBeConfirmed;
            if (payload.source !== undefined) {
                if (payload.source) {//If the source is set, we'll have some other properties to add too
                    objectToUpdate.source = payload.source;
                    const run = state.project.runs.find(run => run.id === objectToUpdate.source);
                    //Allow the inflow flow to match whatever the source run is discharging
                    Object.defineProperty(objectToUpdate, 'flow_lps', {
                        configurable: true,
                        enumerable: true,
                        get: () => +(run.results.channel_discharge ?? run.results.flow)?.toFixed(2) || 0,
                    });
                    Object.defineProperty(objectToUpdate, 'source_index', {
                        configurable: true,
                        enumerable: true,
                        get() {//Bonus getter for serialisation, so Django can know what the source is too
                            return state.project.runs.findIndex(run => run.id === this.source);
                        },
                    });
                    //Bonus getter too, used for the PDFs where only one run might be sent so the index is unhelpful
                    Object.defineProperty(objectToUpdate, 'name', {
                        configurable: true,
                        enumerable: true,
                        get: () => run.name,
                    });
                } else if (objectToUpdate.source) {//If the source is unset, the other properties need to be removed too
                    delete objectToUpdate.source;
                    delete objectToUpdate.flow_lps;
                    delete objectToUpdate.source_index;
                    delete objectToUpdate.name;
                }
            }
            if (payload.flow_lps !== undefined) objectToUpdate.flow_lps = payload.flow_lps;
            if (payload.connection !== undefined) objectToUpdate.connection = payload.connection;
            if (payload.newAccessoryID !== undefined) objectToUpdate.accessoryId = payload.newAccessoryID;
        },
        updateAccessory(state, payload) {
            const objectToUpdate = state.project.runs[state.selectedRun].accessories.find(a => {
                return a.id === payload.id
            })
            if (payload.distance_m !== undefined) objectToUpdate.distance_m = payload.distance_m
            if (payload.type !== undefined) objectToUpdate.type = payload.type
            if (payload.toBeConfirmed !== undefined) objectToUpdate.toBeConfirmed = payload.toBeConfirmed;
        },
        setPipeLength(state, payload) {
            state.project.runs[state.selectedRun].pipe_length_m = payload
        },
        setPipeDiameter(state, payload) {
            state.project.runs[state.selectedRun].pipe_diameter_mm = payload
        },
        setPipeSlope(state, payload) {
            state.project.runs[state.selectedRun].pipe_slope = payload
        },
        setNotes(state, payload) {
            state.project.runs[state.selectedRun].notes = payload
        },
        setIrregularAreas(state, {leftAreas, rightAreas}) {
            state.project.runs[state.selectedRun].irregularCatchmentData = {leftAreas, rightAreas};
        },
        updateAreaMeasures(state, payload) {
            const areaToUpload = payload.area;
            const oldArea = (areaToUpload.start_width_m + areaToUpload.end_width_m) / 2 * areaToUpload.length_m;
            const areas = state.project.runs[state.selectedRun].irregularCatchmentData;
            console.assert((payload.position === 'left' ? areas.leftAreas : areas.rightAreas).includes(areaToUpload));
            console.warn(`Updating area: [${areaToUpload.start_width_m}, ${areaToUpload.end_width_m}] => [${payload.startWidth}, ${payload.endWidth}]`);
            if (Number.isFinite(payload.startWidth)) areaToUpload.start_width_m = payload.startWidth;
            if (Number.isFinite(payload.endWidth)) areaToUpload.end_width_m = payload.endWidth;
            const newArea = (areaToUpload.start_width_m + areaToUpload.end_width_m) / 2 * areaToUpload.length_m;
            state.project.runs[state.selectedRun].drainage_area_m2 += newArea - oldArea;
            console.info('Area change', oldArea, '=>', newArea, `(net change ${newArea - oldArea}, now: ${state.project.runs[state.selectedRun].drainage_area_m2})`)
        },
        updatePermeability(state, payload) {
            /*switch (payload.position) {
                case 'left': {
                    const leftAreas = state.project.runs[state.selectedRun].irregularCatchmentData.leftAreas
                    const areaToUpdate = leftAreas.find(a => Math.abs(a.xOrigin - payload.xOrigin) < 1)
                    areaToUpdate.permeability = payload.permeability
                    break
                }
                case 'right': {
                    const rightAreas = state.project.runs[state.selectedRun].irregularCatchmentData.rightAreas
                    const areaToUpdate = rightAreas.find(a => Math.abs(a.xOrigin - payload.xOrigin) < 1)
                    areaToUpdate.permeability = payload.permeability
                    break
                }
            }*/
            const areaToUpdate = payload.area;
            const areas = state.project.runs[state.selectedRun].irregularCatchmentData;
            console.assert((payload.position === 'left' ? areas.leftAreas : areas.rightAreas).includes(areaToUpdate));
            console.warn(`Updating permeability: ${areaToUpdate.permeability.name} (${areaToUpdate.permeability_value}) => ${payload.permeability.name} (${payload.permeabilityValue})`);
            areaToUpdate.permeability = payload.permeability;
            areaToUpdate.permeability_value = payload.permeabilityValue;
        },
        handleDivide(state, payload) {
            console.info(`Dividing ${payload.position}, using`, payload);
            let areas;
            switch (payload.position) {
                case 'left': {
                    areas = state.project.runs[state.selectedRun].irregularCatchmentData.leftAreas;
                    break
                }
                case 'right': {
                    areas = state.project.runs[state.selectedRun].irregularCatchmentData.rightAreas;
                    break
                }
                default:
                    console.error('Unexpected division position', payload.position, 'in', payload);
                    return;
            }
            const areaToSplit = payload.area;

            let middleWidth_m; //This could be handled together with Math.min & max, but it is clearer this way
            if (areaToSplit.start_width_m > areaToSplit.end_width_m) {
                //   |\
                // h | \
                //   |__\ a
                const height_m = areaToSplit.start_width_m - areaToSplit.end_width_m;
                const angle = Math.tan(height_m / areaToSplit.length_m);
                middleWidth_m = areaToSplit.end_width_m + (payload.rightSplit * angle);
            } else {
                //     /|
                //    / | h
                // a /__|
                const height_m = areaToSplit.end_width_m - areaToSplit.start_width_m;
                const angle = Math.tan(height_m / areaToSplit.length_m);
                middleWidth_m = areaToSplit.start_width_m + (payload.leftSplit * angle);
            }

            const oldArea = (areaToSplit.start_width_m + areaToSplit.end_width_m) / 2 * areaToSplit.length_m;
            const splitArea = {
                start_width_m: middleWidth_m,
                end_width_m: areaToSplit.end_width_m,
                length_m: payload.rightSplit,
                xOrigin: areaToSplit.xOrigin + payload.leftSplit,
                permeability: areaToSplit.permeability,
                permeability_value: areaToSplit.permeability_value,
            }
            areaToSplit.length_m = payload.leftSplit
            areaToSplit.end_width_m = middleWidth_m

            const areaIndex = areas.indexOf(areaToSplit);
            console.debug("Before split", areas.map(area => `${area.length_m}m from ${area.start_width_m} to ${area.end_width_m}`));
            areas.splice(areaIndex + 1, 0, splitArea);
            console.debug("After split", areas.map(area => `${area.length_m}m from ${area.start_width_m} to ${area.end_width_m}`));

            const newArea = [areaToSplit, splitArea].reduce((sum, area) => sum + (area.start_width_m + area.end_width_m) / 2 * area.length_m, 0);
            state.project.runs[state.selectedRun].drainage_area_m2 += newArea - oldArea;
            console.info('Area change', oldArea, '=>', newArea, `(net change ${newArea - oldArea}, now: ${state.project.runs[state.selectedRun].drainage_area_m2})`)
        },
        handleMerge(state, {position, areas}) {
            const firstArea = areas[0];
            const lastArea = areas.at(-1);
            const {[`${position}Areas`]: allAreas} = state.project.runs[state.selectedRun].irregularCatchmentData;

            const from = allAreas.indexOf(firstArea);
            const to = allAreas.indexOf(lastArea, from);
            console.assert(from < to, "Invalid areas to merge", areas, from, to, allAreas);
            console.assert(to - from === areas.length - 1, "Invalid order of areas to merge", areas, from, to, allAreas);

            //Put the merged area in the same index as 'from', squishing up to (and including) 'to'
            const oldArea = allAreas.splice(from, areas.length, {
                start_width_m: firstArea.start_width_m,
                end_width_m: lastArea.end_width_m,
                length_m: areas.reduce((sum, area) => sum + area.length_m, 0),
                xOrigin: firstArea.xOrigin,
                permeability: firstArea.permeability,
                permeability_value: firstArea.permeability_value,
            }).reduce((sum, area) => sum + (area.start_width_m + area.end_width_m) / 2 * area.length_m, 0);
            const newArea = (allAreas[from].start_width_m + allAreas[from].end_width_m) / 2 * allAreas[from].length_m;
            state.project.runs[state.selectedRun].drainage_area_m2 += newArea - oldArea;
            console.info('Area change', oldArea, '=>', newArea, `(net change ${newArea - oldArea}, now: ${state.project.runs[state.selectedRun].drainage_area_m2})`)
        },
        setIsCalculating(state, payload) {
            state.isCalculating = payload
        },
        setHasInputChanged(state, payload) {
            state.project.runs[state.selectedRun].hasInputChanged = payload
            // if (payload === true) {
            //     // Reset inflows and accessories in an input has changed (e.g. channel length)
            //     state.project.runs[state.selectedRun].inflows = []
            //     state.project.runs[state.selectedRun].accessories = []
            // }
        },
        setChannelChoices(state, payload) {
            state.project.runs[state.selectedRun].channelChoices = payload
        },
        setNoBuildInFall(state, payload) {
            state.project.runs[state.selectedRun].noBuildInFall = payload
        },
        setSelectedChannelType(state, payload) {
            state.project.runs[state.selectedRun].selectedChannelType = payload
        },
        setAdvancedMode(state, payload) {
            state.project.runs[state.selectedRun].advanced = payload
        },
        setErrors(state, payload) {
            state.project.runs[state.selectedRun].errors[payload['errorName']] = payload.value
        },
        setLoading(state, payload) {
            switch (payload) {
                case 'LCN20':
                    payload = 'L';
                    break;
                case 'LCN50':
                    payload = 'M';
                    break;
                case 'LCN70':
                    payload = 'N';
                    break;
                case 'LCN110':
                    payload = 'O';
                    break;
                default:
                    payload = payload.charAt(0);
                    break;
            }
            state.project.runs[state.selectedRun].loading = payload
        },
        setGrating(state, payload) {
            state.project.runs[state.selectedRun].grating = payload;
        },
        clearChannelUse(state) {
            const run = state.project.runs[state.selectedRun];
            run.channels.length = 0;
            this.commit('setDischargeSetting', 'free-outflow');
        },
        updateChannelUse(state, {channel, value}) {
            const {channels} = state.project.runs[state.selectedRun];
            const index = channels.indexOf(channel);

            //If the last channel has been removed, switch the discharge back to free outflow
            if (index >= 0 && value <= 0 && channels.length === 1) this.commit('setDischargeSetting', 'free-outflow');

            channel.number = value;
            if (index < 0) {
                if (value > 0) {//Watch out for an unused channel having its value reasserted as 0 clearing the box out
                    channels.push(channel);
                    state.project.runs[state.selectedRun].stepped ||= channels.length > 1;
                }
            } else if (value <= 0) {
                channels.splice(index, 1);
            }
        },
        updateConcreteChannelUse(state, {width, depth, roughness}) {
            const {channels, concreteChannel} = state.project.runs[state.selectedRun];

            if (!channels.includes(concreteChannel)) {
                channels.push(concreteChannel);
            }

            if (width !== undefined) concreteChannel.width = width;
            if (depth !== undefined) concreteChannel.depth = depth;
            if (roughness !== undefined) concreteChannel.roughness = roughness;
        },
        setHeadCutting(state, payload) {
            state.project.runs[state.selectedRun].hasHeadCutting = payload;
        },
        setHeadCuttingDistance(state, payload) {
            state.project.runs[state.selectedRun].headCutting.distance_in_mm = payload;
        },
        setHeadCuttingAngle(state, payload) {
            state.project.runs[state.selectedRun].headCutting.angle_deg = payload;
        },
        setHeadCuttingSide(state, leftCut) {
            state.project.runs[state.selectedRun].headCutting.cut_from_left = leftCut;
        },
        setDischargeSetting(state, payload) {
            const run = state.project.runs[state.selectedRun];
            run.dischargeSetting = payload;
            if (payload !== 'outflow-in-trashbox') {
                 run.outflowTrashbox = undefined;
            }
            if (payload !== 'end-cap-with-outlet') {
                run.outflowEndcap = undefined;
            }
            if (payload !== 'modification-last-element') {
                run.hasDrillingAtLast = false;
            }
        },
        setOutflowTrashbox(state, payload) {
            state.project.runs[state.selectedRun].outflowTrashbox = payload;
        },
        setOutflowEndcap(state, endcap) {
            state.project.runs[state.selectedRun].outflowEndcap = endcap;
        },
        setHasDrillingAtLast(state, payload) {
            state.project.runs[state.selectedRun].hasDrillingAtLast = payload
        },
        setDistanceEdgeToDrillingCenterValue(state, payload) {
            state.project.runs[state.selectedRun].drilling.hole_distance_mm = payload;
        },
        setDrillingDiameterValue(state, payload) {
            state.project.runs[state.selectedRun].drilling.hole_diameter_mm = payload;
        },
        setDrillingAtLastPosition(state, payload) {
            state.project.runs[state.selectedRun].drilling.position = payload;
        },
        setDischargeCutting(state, payload) {
            state.project.runs[state.selectedRun].hasDischargeCutting = payload;
        },
        setDischargeCuttingDistance(state, payload) {
            state.project.runs[state.selectedRun].dischargeCutting.distance_in_mm = payload;
        },
        setDischargeCuttingAngle(state, payload) {
            state.project.runs[state.selectedRun].dischargeCutting.angle_deg = payload;
        },
        setDischargeCuttingSide(state, leftCut) {
            state.project.runs[state.selectedRun].dischargeCutting.cut_from_left = leftCut;
        },
        /** Set the current tab index */
        setTabIndex: (state, payload) => state.tabIndex = payload,
        /** Set the project name to the given payload value */
        setProjectName: (state, payload) => state.project.details.name = payload,
        /** Set the project location to the given payload value */
        setLocation: (state, payload) => state.project.details.location = payload,
        /** Set the project reference to the given payload value */
        setReference: (state, payload) => state.project.details.reference = payload,
        /** Set the project date to the given payload value */
        setDate: (state, payload) => state.project.details.date = payload,
        /** Set the project date to the given payload value */
        setCompany: (state, payload) => state.project.details.company = payload,
        /** Set the project designer to the given payload value */
        setDesigner: (state, payload) => state.project.details.designer = payload,
        /** Set the project telephone number to the given payload value */
        setTelephone: (state, payload) => state.project.details.telephone = payload,
        /** Set the project email to the given payload value */
        setEmail: (state, payload) => state.project.details.email = payload,
        /** Sets whether the project is shared with Hauraton (when saved) to the given value */
        setSharedWithHauraton: (state, shared) => state.project.sharedWithHauraton = shared,

        /** Sets the currently selected run to the first the project has */
        resetSelectedRun(state) {
            //Reset the selected tab if the run has not been calculated yet
            if (!state.project.runs[0].isCalculated) state.tabIndex = 0;

            state.selectedRun = 0;
        },
        /**
         * Sets the currently selected run to the given run/run ID/run index
         *
         * @param {store.state} state The currently stored things
         * @param {Object} payload The parameter passed to {@link Store#commit}
         * @param {Run} [payload.run] The run object to set
         * @param {string} [payload.id] The unique ID of the run to set
         * @param {number} [payload.index] The index of the run to set
         */
        setSelectedRun(state, {run, id, index}) {
            let newIndex;
            if (run) {
                newIndex = state.project.runs.indexOf(run);

                if (newIndex < 0) {
                    console.error("Unable to find run", run);
                    return;
                }
            } else if (id) {
                newIndex = state.project.runs.findIndex(run => run.id === id);

                if (newIndex < 0) {
                    console.error("Unable to find run with ID", id);
                    return;
                }
            } else if (index) {
                if (index >= state.project.runs.length) {
                    console.error("Index", index, "is greater than the number of runs");
                    return;
                }

                newIndex = index;
            } else {
                console.warn("No parameter provided to select run");
                return;
            }

            //Reset the selected tab if the run has not been calculated yet
            if (!state.project.runs[newIndex].isCalculated) state.tabIndex = 0;

            state.selectedRun = newIndex;
        },

        /** Add a run to the project, optionally with the given payload as a name */
        addRun(state, payload) {
            let name;
            if (!payload) {
                //Come up with a new name, if there are n runs, the next should be Run n+1
                //Runs can be renamed though, so we'll be careful not to name two the same
                let runNumber = state.project.runs.length + 1;

                do {
                    name = `Run ${runNumber++}`;
                } while (state.project.runs.some(run => run.name === name));
            } else {
                name = payload;
            }
            const newRun = Run.new(name, state.user.default_advanced_mode, false);
            state.project.runs.push(newRun);
            state.selectedRun = state.project.runs.findIndex(r => r.id === newRun.id);
            state.tabIndex = 0;
        },
        /** Add the given run to the project */
        loadRun(state, run) {
            Sentry.addBreadcrumb({
                category: 'loading',
                message: `Loading run ${run.saveID}`,
                level: Sentry.Severity.Info,
            });
            const newRun = new Run();
            Object.assign(newRun, run);
            console.assert(!!state.productGroups, "Haven't managed to load product groups yet?");
            // console.debug(newRun.selectedSystem, state.productGroups);
            newRun.selectedSystem = state.productGroups.find(group => group.slug === newRun.selectedSystem.slug);
            if (run.permeability_description) {//Complex catchment runs won't come with a single permeability to set
                newRun.permeability_description = permeabilities.find(perm => perm.name === newRun.permeability_description);
            }
            for (const side of Object.values(newRun.irregularCatchmentData)) {
                for (const area of side) {
                    area.permeability = permeabilities.find(perm => perm.name === area.permeability);
                }
            }
            if (newRun.concreteChannelSettings) {
                const concreteChannel = newRun.concreteChannel;
                newRun.channels = [concreteChannel];
                concreteChannel.width = newRun.concreteChannelSettings.width;
                concreteChannel.depth = newRun.concreteChannelSettings.depth;
                concreteChannel.roughness = newRun.concreteChannelSettings.roughness;
                delete newRun.concreteChannelSettings;
            } else {
                newRun.channels = newRun.channels.map(({slug, number}) => {
                    const channel = newRun.channelChoices.find(channel => channel.slug === slug)
                    channel.number = number;
                    return channel;
                });
            }
            if (newRun.selectedSystem.product_type === 'C') {
                for (const inflow of newRun.inflows) {
                    inflow.id = uuidv4();
                    //Inflows to access boxes will have the corresponding accessory ID added in calculateAccessoryChange
                }
                if (!newRun.isCalculated) {
                    //Run couldn't be calculated, avoid relying on the materials
                    for (const accessory of newRun.accessories) {
                        accessory.id = uuidv4();
                    }
                    linkInflowsToAccessBoxes(newRun);
                } else {
                    calculateAccessoryChange(newRun);
                }
            }
            state.project.runs.push(newRun);
        },
        /**
         * Sets the name of the run with the given ID to the given string
         *
         * @param {store.state} state The currently stored things
         * @param {string} id The ID of the run to change the name of
         * @param {string} name The new name for the run to have
         */
        renameRun(state, {id, name}) {
            if (state.project.runs.some(run => run.name === name)) {
                const original = name;
                let duplicate = 1;
                do {
                    name = `${original} (${++duplicate})`;
                } while (state.project.runs.some(run => run.name === name));
            }
            for (const run of state.project.runs) {
                if (run.id === id) {
                    run.name = name;
                    break;
                }
            }
        },
        /** Sets the results of the current run */
        setRunResults(state, payload) {
            const currentRun = selectRun(state, payload.runID);
            delete payload.runID; //Don't save this in the results
            currentRun.results = payload;
            currentRun.results.calculated = true;
            currentRun.results.advanced = currentRun.advanced;

            if (currentRun.selectedSystem.product_type === 'C') {
                calculateAccessoryChange(currentRun);
            }

            // We need the following line otherwise as soon as the user enters the first
            // input (before pressing the 'calculate' button), 'hasInputChanged' will
            // be set to 'true' and the button will have the red outline after the
            // first calculation - unwanted behaviour.
            state.project.runs[state.selectedRun].hasInputChanged = false
        },
        /** Replace the current runs with the provided payload */
        reorderRuns(state, payload) {
            console.assert(Array.isArray(payload), payload); //Avoid accidents replacing all the runs
            const selected = state.project.runs[state.selectedRun].id;
            state.project.runs = payload;
            state.selectedRun = payload.findIndex(run => run.id === selected);
            console.assert(state.selectedRun >= 0, selected, payload);
        },
        /** Deletes a run from the project, based on the given ID */
        deleteRun(state, {id}) {
            if (state.project.runs.length === 1) {
                //There is only one run, so we'll squish it with a fresh one
                state.project.runs[0] = Run.new("Run 1", state.user.default_advanced_mode);
            } else {
                const index = state.project.runs.findIndex(run => run.id === id);
                const [{first: wasFirst}] = state.project.runs.splice(index, 1);
                //Adjust the selected run downwards if it has moved (or been deleted)
                if (state.selectedRun > 0 && state.selectedRun >= index) state.selectedRun--;
                //If the deleted run was the original run, pass the honour onto the new first (ordered) run
                if (wasFirst) state.project.runs[0].first = true;
            }
            console.assert(!!state.project.runs[state.selectedRun], state.selectedRun, state.project.runs);
        },
        /** Un-calculates the current run, taking it back to a pre-calculated state  */
        resetCalculationData(state) {
            state.tabIndex = 0; //All the calculation data is going
            state.project.runs[state.selectedRun].results = {
                calculated: false,
            };
        },
        /** Resets any information stored in the current run */
        resetRunData(state) {
            state.tabIndex = 0; //No more calculation data in a fresh run
            const {name, first: wasFirst} = state.project.runs[state.selectedRun];
            state.project.runs[state.selectedRun] = Run.new(name, state.user.default_advanced_mode, wasFirst);
        },
        /** Deletes all runs from the project and resets the project details */
        resetRuns(state) {
            state.tabIndex = 0;
            state.selectedRun = 0;
            state.project.runs = [Run.new("Run 1", state.user.default_advanced_mode)];
            //Clear out any project information
            state.project.rainfallUnits = state.user.rainfallUnits;
            delete state.project.details.saveID; //Avoid any problems trying to save an empty string
            for (const detail in state.project.details) {
                state.project.details[detail] = '';
            }
        },
        /** Duplicates the run with the given ID in the project */
        duplicateRun(state, {id}) {
            const run = state.project.runs.find(run => run.id === id);

            let name;
            if (/^Run \d+$/.test(run.name)) {
                let runNumber = parseInt(run.name.substring(4));

                do {
                    name = `Run ${++runNumber}`;
                } while (state.project.runs.some(run => run.name === name));
            } else {
                name = run.name + " - Copy";

                let attempt = 2;
                while (state.project.runs.some(run => run.name === name)) {
                    name = `${run.name} - Copy (${attempt++})`;
                }
            }

            const newRun = new Run(name, run)
            state.project.runs.push(newRun);
            state.selectedRun = state.project.runs.findIndex(r => r.id === newRun.id);
        },

        setDrainageSystem(state, payload) {
            "use strict";
            state.project.runs[state.selectedRun].selectedSystem = payload;
        },
        setDrainageSystems(state, payload) {
            state.productGroups = payload;
        },

        setRunLocation(state, {id, channelRunLocation}) {
            const run = selectRun(state, id);
            run.location = channelRunLocation;
        },
        setRainfallIntensity(state, {id, rainfallIntensity}) {
            const run = selectRun(state, id);
            run.rainfall.rainfall_mm_per_hr = rainfallIntensity;
        },
        setStormDuration(state, {id, stormDuration}) {
            const run = selectRun(state, id);
            run.rainfall.storm_duration = stormDuration;
        },
        setSelectedSystem(state, payload) {
            state.project.runs[state.selectedRun].selectedSystem = payload;
        },
        setChannelLength(state, {id, channelLength}) {
            const run = selectRun(state, id);
            run.channel_length_m = channelLength;

            if (run.drainage_area_m2) {
                 run.catchment_depth_m = run.drainage_area_m2 / channelLength / 2;
            }
            if ((run.upperElevation > 0 || run.lowerElevation > 0) && channelLength > 0) {
                const slope = (run.upperElevation - run.lowerElevation) / channelLength * 100;
                run.ground_slope = Math.round(slope * 100) / 100;
            }
        },
        setCatchmentDepth(state, payload) {
            const run = state.project.runs[state.selectedRun];
            run.catchment_depth_m = payload;

            if (run.channel_length_m) {
                run.drainage_area_m2 = payload * 2 * run.channel_length_m;
            }
        },
        setCatchmentArea(state, payload) {
            const run = state.project.runs[state.selectedRun];
            run.drainage_area_m2 = payload.catchmentArea;

            if (run.channel_length_m) {
                 run.catchment_depth_m = run.drainage_area_m2 / run.channel_length_m / 2;
            }
        },
        setCalculationMethod(state, {id, calculationMethod}) {
            const run = selectRun(state, id);
            run.calculation_method = calculationMethod;
        },
        setSlopeSetting(state, {id, slopeSetting}) {
            const run = selectRun(state, id);
            run.slopeSetting = slopeSetting;
        },
        setGroundSlope(state, {id, groundSlope}) {
            const run = selectRun(state, id);
            run.ground_slope = groundSlope;
        },
        setUpperElevation(state, {id, elevation}) {
            const run = selectRun(state, id);
            run.upperElevation = elevation;

            if (run.channel_length_m > 0) {
                const slope = (elevation - run.lowerElevation) / run.channel_length_m * 100;
                run.ground_slope = Math.round(slope * 100) / 100;
            }
        },
        setLowerElevation(state, {id, elevation}) {
            const run = selectRun(state, id);
            run.lowerElevation = elevation;

            if (run.channel_length_m > 0) {
                const slope = (run.upperElevation - elevation) / run.channel_length_m * 100;
                run.ground_slope = Math.round(slope * 100) / 100;
            }
        },
        setStepped(state, {id, isStepped}) {
            const run = selectRun(state, id);
            console.assert(typeof isStepped === 'boolean', "Didn't pass stepped value as a boolean!");
            run.stepped = isStepped;
        },
        setExtensionHat(state, {id, hasExtension}) {
            const run = selectRun(state, id);
            run.has_body_extension = hasExtension;
        },
        setWaterInflow(state, {id, waterInflow}) {
            const run = selectRun(state, id);
            run.water_inflow = waterInflow;
        },
        setControlledDischarge(state, {id, controlledDischarge, results})  {
            const run = selectRun(state, id);
            run.controlled_outflow = controlledDischarge;
            //Clear out any saved results if the discharge flow is reset
            run.controlledDischargeResults = controlledDischarge > 0 ? results : undefined;
        },
        setMinimumChannelSize(state, {id, minimumChannelSize}) {
            const run = selectRun(state, id);
            run.minimumChannelSize = minimumChannelSize;
        },

        setCatchmentShape(state, payload) {
            state.project.runs[state.selectedRun].catchmentShape = payload.catchmentShape;
        },

        setPermeabilityName(state, payload) {
            console.assert(payload !== undefined && payload !== null);
            state.project.runs[state.selectedRun].permeability_description = payload
        },
        setPermeability(state, payload) {
            const runToUpdate = selectRun(state, payload.id);
            if (payload.position) {
                switch (payload.position) {
                    case 'top': {
                        const sectionToUpload = runToUpdate.catchments.leftSections.find(s => s.xOrigin === payload.section.xOrigin)
                        sectionToUpload.permeability = payload.section.permeability
                    }
                        break
                    case 'bottom': {
                        const sectionToUpload = runToUpdate.catchments.rightSections.find(s => {
                            return Math.abs(s.xOrigin - payload.section.xOrigin) < 1
                        })
                        sectionToUpload.permeability = payload.section.permeability
                    }
                }
            } else {
                runToUpdate.permeability = payload.permeability;
            }
        },
        setSuperCriticalWarnings(state, off) {
            state.skipSuperCriticalFlowWarnings = off;
        },
    },
    actions: {
        async getProductGroups({state, commit}) {
            if (!state.productGroups) {
                const response = await fetch(`${BASE_URL}/drainage/product/`);
                if (!response.ok) return Promise.reject(response);
                commit('setDrainageSystems', await response.json());
            }

            return state.productGroups;
        },
        generateIrregularCatchment({commit}, {channelLength_m, catchmentArea_m2}) {
            if (channelLength_m <= 0 || catchmentArea_m2 <= 0) return false;
            const catchmentHeight_m = catchmentArea_m2 / channelLength_m;
            const areaHeight_m = catchmentHeight_m / 2;

            const leftAreas = [{
                length_m: channelLength_m,
                start_width_m: areaHeight_m,
                end_width_m: areaHeight_m,
                xOrigin: 0,
                permeability: ConcretePavement,
                permeability_value: ConcretePavement.value,
            }];
            const rightAreas = [{
                length_m: channelLength_m,
                start_width_m: areaHeight_m,
                end_width_m: areaHeight_m,
                xOrigin: 0,
                permeability: ConcretePavement,
                permeability_value: ConcretePavement.value,
            }];

            commit('setIrregularAreas', {leftAreas, rightAreas});
            return true;
        },
        doCalculate({dispatch, commit}) {
            commit('setIsCalculating', true);

            try {
                return dispatch('requestCalculation').finally(() => {
                    commit('setIsCalculating', false);
                });
            } catch (e) {//Failed to start sending the calculation, indicates a bug
                console.error(e);
                commit('setIsCalculating', false);
                alert("Unfortunately, something went wrong sending a calculation");
            }
        },
        requestCalculation({getters, commit}) {
            // Validate various inputs
            const selectedRun = getters.selectedRun;
            if (getters.isAdvancedMode && !getters.usedChannelLengthCorrect) {
                commit('setErrors', {errorName: 'lengthsNotMatching', value: true});
                return Promise.reject({untranslatedMessage: 'lengthDoNotMatch'});
            }

            if (!selectedRun.hasValidArea) {
                return Promise.reject({message: "Drainage area is too large for the channel length"});
            }

            // Check if needs loading data
            if (!getters.needsLoading) {
                selectedRun.loading = undefined
            }

            // Check if needs grating data
            if (!getters.needsGrating) {
                selectedRun.grating = undefined
            } else if (!selectedRun.grating) {
                commit('resetCalculationData');
                return Promise.reject({silentFail: true});
            }

            if (selectedRun.dischargeSetting === 'outflow-in-trashbox' && selectedRun.outflowTrashbox) {
                const shared = getters.runs.find(run => run.id === selectedRun.outflowTrashbox);
                if (shared) {
                    return fetch(`${BASE_URL}/drainage/calculate_shared/`, {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({
                            main: shared.getCalculationData(getters.runs),
                            second: selectedRun.getCalculationData(getters.runs),
                        }),
                    }).then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            commit('setCalculationError', true);
                            return Promise.reject(response);
                        }
                    }).then(data => {
                        commit('setCalculationError', false);
                        commit('setRunResults', {
                            runID: shared.id,
                            ...data.main,
                        });
                        commit('setRunResults', data.second);
                        return data;
                    }, error => {
                        commit('resetCalculationData');
                        return Promise.reject(error);
                    });
                }
            }

            return fetch(`${BASE_URL}/drainage/calculate/`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(selectedRun.getCalculationData(getters.runs)),
            }).then(response => {
                if (response.ok) {
                    selectedRun.errors.lengthsNotMatching = false;
                    return response.json();
                } else {
                    commit('setCalculationError', true)
                    return Promise.reject(response);
                }
            }).then(data => {
                commit('setCalculationError', false)
                commit('setRunResults', data);
                return data;
            }, error => {
                commit('resetCalculationData');
                return Promise.reject(error);
            });
        },
        async loadProject({commit}, {request, beforeLoad, loadingCallback}) {
            async function safeFetch(url, init) {
                try {
                    return await fetch(url, init);
                } catch (e) {
                    e.skipSentry = true; //Avoid catching local network errors, which is what these will be
                    throw e;
                }
            }
            try {
                commit('setIsCalculating', true);
                const response = await safeFetch(`${BASE_URL}/drainage/load/`, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(request),
                });

                if (response.ok) {
                    beforeLoad?.();
                    const project = await response.json();
                    const total = project.schedule.length;
                    loadingCallback?.(0, total);
                    let runMap = await Promise.all(project.schedule.map(async (part, i) => {
                        const response = await safeFetch(`${BASE_URL}/drainage/load/${part}/`, {
                            method: "GET",
                            headers: {"Content-Type": "application/json"},
                        });
                        loadingCallback?.(i + 1, total);
                        return response.ok ? await response.json() : Promise.reject(response);
                    }));
                    runMap = new Map(runMap.flat(1));
                    //The runs might arrive out of order, so we use the schedule to reassemble them
                    const runs = project.schedule.map(id => runMap.get(id));
                    delete project.schedule;
                    commit('loadProject', {project, runs});
                } else {
                    console.warn("Project loading went badly", response);
                }
            } finally {
                commit('setIsCalculating', false);
            }
        },
    }
});
store.watch((state, getters) => getters.usedChannelLengthCorrect, correct => {
    store.commit('setErrors', {errorName: 'lengthsNotMatching', value: !correct});
})
